import "./styles/app.css";

import Content from "./Content";
// import Footer from "./Footer";
// import Header from "./Header";

const App = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default App;
