import { useEffect, useState } from "react";
import { useSpring, a, config } from "react-spring";

const ankahImg = new URL("./assets/ankah.png", import.meta.url);
const ankahDeadImg = new URL("./assets/ankah-dead.png", import.meta.url);
const meowSound = new URL("./assets/meow.mp3", import.meta.url);
const meowHit = new URL("./assets/meow-hit.mp3", import.meta.url);

const ankhaSpeech = [
  "This is your last chance...",
  "Oh yeah? If you don't have anything nice to say, don't say anything at all.",
  "Mph...All that glitters is not gold. This is a lesson you should learn.",
  "Now you are pussying me off!.",
  "Argh! Me Meow >:3!!",
  `I shouldn't say this, but you should limit your wardrobe to outfits you can actually pull off, know what I mean?`,
  "Ouch! How dare you lie your filthy hands on me!",
  "Wanna talk to Mel? Over my dead body!",
];

const Content = () => {
  const [ankahLifes, setAnkahLifes] = useState(7);
  const ankaSpeech = ankhaSpeech[ankahLifes];
  const isAnkahDead = !ankahLifes;
  const [spring, set] = useSpring(() => ({
    opacity: 1.0,
    "pointer-events": "auto",
    config: config.molasses,
  }));
  const [linksSpring, setLinksSpring] = useSpring(() => ({
    opacity: 0,
    pointerEvents: "none",
    config: config.molasses,
  }));

  const finalImg = !isAnkahDead ? ankahImg : ankahDeadImg;

  const handleOnClick = () => {
    new Audio(meowHit).play();

    setAnkahLifes(ankahLifes - 1);
  };

  useEffect(() => {
    if (ankahLifes) {
      set({
        opacity: (100 * ankahLifes) / 7 / 100 + 0.2,
      });
    }
  }, [ankahLifes]);

  useEffect(() => {
    if (isAnkahDead) {
      new Audio(meowSound).play();

      set({
        opacity: 0,
        "pointer-events": "none",
      });
      setLinksSpring({
        opacity: 1.0,
        pointerEvents: "auto",
      });
    }
  }, [isAnkahDead]);

  return (
    <main className="content">
      <div className="content__main">
        <a.div className="content__main__img" style={{ ...spring }}>
          <img src={finalImg} alt="" onClick={handleOnClick} />
          <div className="content__main__img__speech-bubble">
            <strong>Ankah</strong>: <span>{ankaSpeech}</span>
          </div>
        </a.div>

        <a.div className="content__main__links" style={{ ...linksSpring }}>
          <a href="https://www.linkedin.com/in/mel-macaluso/">Linkedin</a>
          <a href="https://github.com/melmacaluso">Github</a>
          <a href="https://www.instagram.com/itzvenom/?hl=en-gb">Instagram</a>
        </a.div>
      </div>
      {isAnkahDead && (
        <small>
          <pre>Rip headphones users</pre>
        </small>
      )}
    </main>
  );
};

export default Content;
